import { CargoProject } from '../CargoProject'
import FinancialType from '@/enums/FinancialType'
import { Invoice } from '@/models/accounting/Invoice'
import InvoiceLineStatus from '@/enums/InvoiceLineStatus'
import InvoiceType from '@/enums/InvoiceType'
import { Model } from '@/models/Model'
import { Product } from '@/models/accounting/Product'
import Property from '@/decorators/Property'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'
import { VatCode } from '@/models/accounting/VatCode'
import { formatCurrency } from '@/utils/currency'

export class InvoiceLine extends Model {
	protected jsonApiType = 'accounting/invoiceLines'

	@Property()
	public quantity!: number

	@Property()
	public unitPrice!: number

	@Property()
	public description!: string

	@Property()
	public customProduct!: string

	@Property()
	public status!: InvoiceLineStatus

	@Property()
	public financialType!: FinancialType

	@Property()
	public invoiceType!: InvoiceType

	@Property()
	public dependQuantityOnCargoQuantity!: boolean

	private invoice(): ToOneRelation {
		return this.hasOne(Invoice, 'invoice')
	}

	public getInvoice(): Invoice | null {
		return this.getRelation('invoice')
	}

	public setInvoice(invoice: Invoice | null) {
		this.setRelation('invoice', invoice)
	}

	private product(): ToOneRelation {
		return this.hasOne(Product, 'product')
	}

	public getProduct(): Product {
		return this.getRelation('product')
	}

	public setProduct(product: Product) {
		this.setRelation('product', product)
	}

	private vatCode(): ToOneRelation {
		return this.hasOne(VatCode, 'vatCode')
	}

	public getVatCode(): VatCode {
		return this.getRelation('vatCode')
	}

	public setVatCode(vatCode: VatCode) {
		this.setRelation('vatCode', vatCode)
	}

	private precedes(): ToOneRelation {
		return this.hasOne(InvoiceLine, 'precedes')
	}

	public getPrecedes(): InvoiceLine | null {
		return this.getRelation('precedes')
	}

	private precededBy(): ToOneRelation {
		return this.hasOne(InvoiceLine, 'precededBy')
	}

	public getPrecededBy(): InvoiceLine | null {
		return this.getRelation('precededBy')
	}

	public setPrecededBy(line: InvoiceLine | null) {
		this.setRelation('precededBy', line)
	}

	private priceDependentOn(): ToManyRelation {
		return this.hasMany(InvoiceLine, 'priceDependentOn')
	}

	public getPriceDependentOn(): Array<InvoiceLine> {
		return this.getRelation('priceDependentOn') || []
	}

	public setPriceDependentOn(lines: Array<InvoiceLine>) {
		this.setRelation('priceDependentOn', lines)
	}

	private transport(): ToOneRelation {
		return this.hasOne(Transport, 'transport')
	}

	public getTransport(): Transport | null {
		return this.getRelation('transport') || null
	}

	public setTransport(transport: Transport | null) {
		this.setRelation('transport', transport)
	}

	private cargoProject(): ToOneRelation {
		return this.hasOne(CargoProject, 'cargoProject')
	}

	public getCargoProject(): CargoProject | null {
		return this.getRelation('cargoProject') || null
	}

	public setCargoProject(cargoProject: CargoProject | null) {
		this.setRelation('cargoProject', cargoProject)
	}

	public formattedPrice(): string {
		let price = this.unitPrice / 100
		if (this.isPriceDependentOn) {
			price = this.dependentPrice
		}
		return formatCurrency(price, { numberOfDecimals: 3 })
	}

	/**
	 * returns the total value in euros
	 */
	get total(): number {
		const quantity = this.quantity === null || isNaN(this.quantity) ? 0 : this.quantity
		let total
		if (this.isPriceDependentOn) {
			total = this.dependentPrice * (quantity / 100)
		} else {
			const unitPrice = this.unitPrice === null || isNaN(this.unitPrice) ? 0 : this.unitPrice / 100
			total = Number((quantity * unitPrice).toFixed(2))
		}
		return total
	}

	get formattedTotal(): string {
		return formatCurrency(this.total)
	}

	get formattedQuantity(): string {
		if (!this.quantity) return ''
		let formatted = this.quantity.toLocaleString(undefined, {
			maximumFractionDigits: 3
		})
		if (this.isPriceDependentOn) {
			formatted += '%'
		}
		return formatted
	}

	get isPriceDependentOn() {
		return this.getProduct()?.isPercentage
	}

	get dependentPrice() {
		if (!this.isPriceDependentOn) return 0
		return this.getPriceDependentOn().reduce((previousValue, line, idx) => previousValue + line.total, 0)
	}

	get productName() {
		if (this.getProduct()) return this.getProduct().description
		return this.customProduct
	}

	public isLoading: boolean = false
}
